import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './App.scss';
import base from './helpers/airtable';

export default function Main({ url = '/'}) {
  const loc = useLocation();
  const locUser = loc.pathname.match(/\/([^/]+)/gi)?.[0]?.substring(1) || "";

  const [data, setData] = useState(null);
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [adults, setAdults] = useState(null);
  const [children, setChildren] = useState(null);
  const [confirmation, setConfirmation] = useState('');
  const scrollRef = useRef();

  useEffect(() => {
    /**
     * @NOTE if user isn't given via url, use localStorage as fallback
     */
    const localStorageUser = localStorage.getItem("user");
    const user = locUser || localStorageUser;

    // console.warn('** user', {
    //   locUser,
    //   localStorageUser,
    //   user
    // });

    if (user !== '') {
      base('invites').select({
        maxRecords:1,
        filterByFormula: `{name} = '${user}'`
      }).firstPage(function(err, records) {
        if (err) {
          setError(err);
          return;
        }
        setData(records.map(record => {
          return record.fields;
        }))
        setLoading(false);
      });
    } else {
      throw new Error('** err /// user not found');
    }
  }, [loading, locUser]);


  useEffect(() => {
    if (data?.[0] && Object.keys(data?.[0]).length) {
      if (data[0]["first visited"] === undefined) {
        base('invites').update(data[0].id, {
          "first visited": new Date(),
          "visits": 1,
          "device": window.navigator.userAgent
        }, function(err, record) {
          if (err) {
            console.error(err);
            return;
          }
          console.log(record.get('status'));
        });
      } else {
        base('invites').update(data[0].id, {
          "last visited": new Date(),
          "visits": data[0].visits + 1,
          "device": window.navigator.userAgent
        }, function(err, record) {
          if (err) {
            console.error(err);
            return;
          }
          console.log(record.get('status'));
        });
      }

      if (data?.[0]?.adults) {
        setAdults(data[0].adults)
      }

      if (data?.[0]?.children) {
        setChildren(data[0].children)
      }
    }


  }, [loading, data])


  const handleSubmit = (value) => {
    if (data) {
      base('invites').update(data[0].id, {
        "status": value,
        'adults': adults,
        "children": children
      }, function(err, record) {
        if (err) {
          console.error(err);
          return;
        }
        setConfirmation(value);
      });
    }
  }

  const handleRSVP = () => {
    scrollRef.current.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className="App">
      <div className="content">
        <div className="ribbon"></div>
        <div className="card-wrapper">
          <img className="card-bg" src="/assets/img/content-bg.svg" alt="card background" />
          <div className="card-info">
            <img className="twodles" src="/assets/img/oh-twodles.svg" alt="oh twodles" />
            <div className="card-wrapper__text">We can’t believe it, but yes, it’s true!</div>
            <div className="card-wrapper__text"><span>Emma</span> is turning</div>
            <img className="minnie" src="/assets/img/minnie.svg" alt="minnie mouse" />
            <div className="card-wrapper__text">Please join us to celebrate!</div>
            <div className="card-wrapper__details">
              <div className="card-wrapper__details__item">Sat</div>
              <div className="card-wrapper__details__item">July <span>8</span></div>
              <div className="card-wrapper__details__item">11 AM - 1:30 PM</div>
            </div>
            <button onClick={handleRSVP}><label>RSVP</label></button>
          </div>
        </div>
      </div>
      <div className="footer" ref={scrollRef}>
          <div className="footer__item">
            <div className="footer__item__heading">Where</div>
            <div className="footer__item__description">Emma’s clubhouse<br />80-55 210th st.<br />queens village, NY 11427</div>
          </div>
          <div className="footer__item">
          <div className="footer__item__heading">When</div>
            <div className="footer__item__description">Saturday<br />July 8th<br />11 AM - 1:30 PM</div>
          </div>
          <div className="footer__item">
            {(data && confirmation === '') && (
              <>
                <div className="footer__item__attending-wrapper">
                  {data?.[0]?.adults &&
                    <div className="footer__item__attending">
                      <input onChange={e => setAdults(e.target.value)} type="number" id='adults' placeholder={data[0].adults} min="0" max={data[0].adults} />
                      <div className="footer__item__attending__text">Adults</div>
                    </div>
                  }
                  {data?.[0]?.children > 0 &&
                    <div className="footer__item__attending">
                      <input type="number" onChange={e => setChildren(e.target.value)} placeholder={data[0].children} min="0" max={data[0].children} />
                      <div className="footer__item__attending__text">Kids</div>
                    </div>
                  }
                </div>
                <div className="footer__item__heading">will be attending?</div>
                <div className="footer__item__buttons">
                  <button onClick={() => handleSubmit("Attending")}><label>YES</label></button>
                  <button onClick={() => handleSubmit("Not Attending")}><label>NO</label></button>
                </div>
              </>
            )}
            {confirmation.length > 0 &&
              <div className="footer__item__description">{confirmation === "Attending" ? "We can't wait to celebrate with you!" : "Sorry you won't be able to make it.  We hope to see you soon!"}</div>
            }
          </div>
      </div>
    </div>
  );
}
